import React, { useState } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import variables from "../variables";
const cookies = new Cookies();

const CookieBannerContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--white);
  position: fixed;
  padding: 0.4rem 0.6rem 0.4rem 0.8rem;
  border-radius: 50px;
  font-size: 0.8rem;
  z-index: 200;
  transition: opacity 1s, right 1s;
  background: var(--blue);
  .close-icon {
    font-size: 1.3rem;
    margin-left: 0.5rem;
    cursor: pointer;
    flex-shrink: 0;
    &:focus {
      outline: none;
    }
  }
  @media screen and (max-width: ${variables.bpXXSmall}px) {
    font-size: 0.7rem;
    justify-content: space-between;
    left: 1rem;
    text-align: center;
    max-width: 20em;
    margin: 0 auto;
  }
`;

export default function CookieBanner({ setShowCookie, isHome }) {
  const [isAnimating, setIsAnimating] = useState(false);
  return (
    <CookieBannerContainer
      style={{
        opacity: isAnimating ? 0 : 1,
        right: isAnimating ? "-0.5rem" : "1rem",
        top: isHome ? "1rem" : "initial",
        bottom: isHome ? "initial" : "1rem",
      }}
    >
      Our website uses cookies to improve your experience
      <Icon
        icon='carbon:close-outline'
        className='close-icon'
        onClick={() => {
          setIsAnimating(true);
          setTimeout(() => {
            cookies.set("acceptedCookies", true, { path: "/" });
            setShowCookie(false);
          }, 1000);
        }}
        onKeyDown={() => {
          setIsAnimating(true);
          setTimeout(() => {
            cookies.set("acceptedCookies", true, { path: "/" });
            setShowCookie(false);
          }, 1000);
        }}
      />
    </CookieBannerContainer>
  );
}
