import React, { useRef, useEffect, useState } from "react";
import SEO from "../components/SeoComp";
import styled from "@emotion/styled";
// import Sketch from "react-p5";
import { loadableP5 as Sketch } from "../loadable";
import { animateScroll as scroll, scroller } from "react-scroll";
import {
  Engine,
  Events,
  Render,
  Runner,
  Common,
  Matter,
  MouseConstraint,
  Mouse,
  Composite,
  Composites,
  Vertices,
  Body,
  Bodies,
  World,
  Svg,
} from "matter-js";
import variables from "../variables";

const HomeContainer = styled.div`
  height: calc(100vh - 9rem);
  canvas {
    pointer-events: none;
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    height: 28rem;
    canvas {
      pointer-events: auto;
    }
  }
`;

export default function IndexPage() {
  const scene = useRef();
  const [bodies, setBodies] = useState();
  const [chance, setChance] = useState(Math.random() < 0.5);
  // var heights = chance ? [4, 7, 8] : [8, 7, 4];
  // var widths = chance ? [0.575, 0.045, 0.2125] : [0.32, 0.045, 0.45];
  var widths = chance ? [0.58, 0.045, 0.2125] : [0.32, 0.045, 0.45];
  var heights = chance ? [0.21, 1.39, 0.33] : [0.21, 1.39, 0.33];
  var engine;
  var stack;
  var world;
  var canvas;
  var mouseConstraint;

  useEffect(() => {
    return () => {};
  }, []);

  const setup = (p5, canvasParentRef) => {
    // use parent to render the canvas in this ref
    // (without that p5 will render the canvas outside of your component)
    canvas = p5
      .createCanvas(scene.current.offsetWidth, scene.current.offsetHeight)
      .parent(canvasParentRef);
    engine = Engine.create();
    engine.gravity.scale = 0.004;
    // engine.constraintIterations = 18;
    // engine.velocityIterations = 18;
    // engine.positionIterations = 18;
    world = engine.world;

    var canvasWidth = p5.width > 1100 ? 1100 : p5.width;

    var mouse = Mouse.create(canvas.elt);
    var mouseParams = {
      mouse: mouse,
      constraint: {
        stiffness: 0.1,
      },
    };

    mouseConstraint = MouseConstraint.create(engine, mouseParams);
    mouseConstraint.mouse.pixelRatio = p5.pixelDensity();
    World.add(world, mouseConstraint);

    var params = {
      isStatic: true,
    };

    let wallWidth = 100;

    var ground = Bodies.rectangle(
      p5.width / 2,
      p5.height + wallWidth / 4,
      p5.width,
      wallWidth,
      params
    );
    var wall1 = Bodies.rectangle(
      -wallWidth / 2,
      p5.height / 2,
      wallWidth,
      p5.height,
      params
    );
    var wall2 = Bodies.rectangle(
      p5.width + wallWidth / 2,
      p5.height / 2,
      wallWidth,
      p5.height,
      params
    );
    var top = Bodies.rectangle(
      p5.width / 2,
      -wallWidth / 2,
      p5.width,
      wallWidth,
      params
    );

    World.add(world, ground);
    World.add(world, wall1);
    World.add(world, wall2);
    World.add(world, top);

    let count = 0;

    // x, y, columns, rows, column gap, row gap
    stack = Composites.stack(10, 50, 1, 3, 1, 50, function (x, y) {
      let posX;
      let width = canvasWidth * widths[count] * 1.2;
      let height = width * heights[count];
      let whichHalf = Math.random() < 0.5;

      if (count === 0) {
        posX = whichHalf ? 50 : width > p5.width / 2 ? 50 : p5.width / 2;
      } else if (count === 1) {
        posX = p5.width / 2 - width / 2;
        count++;
        return Bodies.circle(posX, y, width, {
          restitution: 0,
          friction: 1,
          frictionStatic: 1,
          frictionAir: 0,
          density: 1,
          width,
          height,
        });
      } else {
        posX = whichHalf ? p5.width - width : 0;
      }

      count++;

      return Bodies.rectangle(posX, y, width, height, {
        restitution: 0,
        friction: 1,
        frictionStatic: 1,
        frictionAir: 0,
        density: 1,
        width,
        height,
        render: {
          opacity: 0,
        },
      });
    });
    setBodies(stack.bodies);

    // add all of the bodies to the world
    Composite.add(world, stack);

    // run the engine
    Runner.run(engine);

    var oldHeight = p5.height;
    var oldWidth = p5.width;

    typeof window !== "undefined" &&
      window.addEventListener("resize", function () {
        if (p5.width !== oldWidth) {
          Body.setPosition(ground, {
            x: p5.width / 2,
            y: p5.height + wallWidth / 4,
          });
          Body.setPosition(wall2, {
            x: p5.width + wallWidth / 2,
            y: p5.height / 2,
          });
          Body.scale(ground, p5.width / oldWidth, 1);
          Body.scale(wall1, p5.height / oldHeight, 1);
          Body.scale(wall2, p5.height / oldHeight, 1);
          oldWidth = p5.width;
          oldHeight = p5.height;
        }
      });
  };

  const draw = (p5) => {
    p5.background("white");
    p5.strokeWeight(0);

    for (var i = 0; i < bodies.length; i++) {
      var circleL = bodies[i];
      var height = circleL.height;
      var width = circleL.width;
      var pos = circleL.position;
      var angle = circleL.angle;
      var words = ["Sculpture", "&", "Poetry"];

      p5.textSize(height);
      p5.textFont("Standard Book");

      var txt1 = words[i];

      p5.push();
      p5.translate(pos.x, pos.y);
      p5.rotate(angle);

      // set rectangle in background, change fill to solid color to see it
      // p5.rectMode(p5.CENTER);
      // p5.fill("red");
      // p5.rect(0, 0, width, height);

      // set text
      p5.fill("#4168F1");
      p5.textAlign(p5.CENTER);
      p5.text(txt1, 0, height / 2);

      p5.pop();
    }
  };

  const windowResized = (p5, e) => {
    p5.resizeCanvas(scene.current.offsetWidth, scene.current.offsetHeight, 0);
  };

  return (
    <>
      <SEO />
      <HomeContainer ref={scene}>
        <Sketch setup={setup} draw={draw} windowResized={windowResized} />
      </HomeContainer>
    </>
  );
}
