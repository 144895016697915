import React, { useEffect } from "react";
import { graphql } from "gatsby";
import {
  formatConferenceDate,
  formatConferenceDateRange,
  formatMonthYear,
  formatTime,
} from "../helpers";
import { Parser } from "html-to-react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import variables from "../variables";
import TableSession from "../components/TableSession";
import Footer from "../components/Footer";
import TableKeynote from "../components/TableKeynote";
import { useState } from "react";
import SEO from "../components/SeoComp";

const PageContainer = styled.div``;

const BylineContainer = styled.div`
  padding: 1rem 1rem 1.2rem;
  line-height: 1.2;
  color: var(--white);
  background: var(--black);
  p {
    max-width: 42rem;
  }
`;

const ConferenceInfo = styled.section`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0 1rem;
  background: var(--clay);
  padding: 1rem;
  .button {
    background: var(--blue);
    color: var(--white);
    border-color: var(--blue);
    align-self: flex-start;
    justify-self: flex-end;
    &:hover {
      background: var(--white);
      border-color: var(--white);
      color: var(--blue);
    }
  }
  .description {
    font-size: 1.5rem;
    line-height: 1.2;
    @media screen and (max-width: 350px) {
      font-size: 1.2rem;
    }
    a {
      color: var(--white);
      text-decoration: underline;
      text-underline-offset: 0.1em;
      text-decoration-thickness: 0.08em;
    }
  }
  .location-time {
    margin-top: 3rem;
    grid-column: 1/-1;
    color: var(--white);
    font-size: 1.5rem;
    .date-circle {
      display: inline-block;
      margin: 0 0.8rem;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: var(--black);
    }
    .gmt {
      margin-left: 0.2rem;
      font-size: 1rem;
    }
    &-time,
    &-date {
      white-space: nowrap;
    }
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    grid-template-columns: 1fr;
    gap: 0;
    .button {
      justify-self: flex-start;
      margin-top: 2rem;
    }
  }
`;

const TableHeader = styled.div`
  position: relative;
  ${variables.conferenceGrid};
  margin: 4rem 1rem 1rem;
  color: var(--clay);
  &:after {
    display: block;
    content: "";
    height: 2px;
    width: 100%;
    background: var(--light-clay);
    grid-column: span 3;
    transform: translateY(-0.6rem);
    z-index: -10;
  }
  div {
    text-transform: uppercase;
    width: fit-content;
    box-sizing: content-box;
    background: var(--white);
  }
  div:first-of-type {
    padding-right: 15px;
  }
  div:nth-of-type(2) {
    padding-left: 15px;
    padding-right: 15px;
  }
  div:last-child {
    padding-left: 15px;
    margin-left: auto;
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    margin: 2rem 1rem 1rem;
  }
`;

const htmlToReactParser = new Parser();

const ConferencePage = ({
  data,
  pageContext,
  setHeaderInfo,
  setShowMenu,
  findSpeaker,
  speakerLeft,
  setSpeakerLeft,
  setPageType,
}) => {
  const { conference } = data;
  const [sortedEvents, setSortedEvents] = useState([]);
  const startDate = new Date(Date.parse(conference.startDate));
  const endDate = new Date(Date.parse(conference.endDate));
  const dateHasPassed = startDate - Date.now() < 0;

  useEffect(() => {
    setSortedEvents(
      conference.events
        .sort(
          (a, b) => new Date(Date.parse(a.date)) - new Date(Date.parse(b.date))
        )
        .reduce((p, c, i, a) => {
          if (!c.startTime) {
            return p;
          }

          const date = new Date(Date.parse(c.date));
          const time = new Date(Date.parse(c.startTime))
            .toLocaleString("en-GB", {
              hour: "numeric",
              hour12: true,
              timeZone: "UTC",
            })
            .slice(-2);
          const key = `${time}-${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;

          if (!p[key]) {
            p[key] = [];
          }

          p[key].push(c);
          return p;
        }, {})
    );
    setShowMenu(false);
    setPageType("conference");
    setHeaderInfo({
      parentLink: "/",
      parentTitle: "<-",
      preTitle: `Conference #${conference.conferenceIndex + 1}`,
      title: conference.title,
      date: formatMonthYear(new Date(Date.parse(conference.startDate))),
    });
    return () => {
      setPageType("");
      setHeaderInfo({
        parentLink: "",
        parentTitle: "",
        preTitle: "",
        title: "",
        date: "",
      });
    };
  }, [
    conference.events,
    conference.startDate,
    conference.title,
    pageContext.number,
    setHeaderInfo,
    setPageType,
    setShowMenu,
  ]);

  const timeTables = Object.keys(sortedEvents).map((group, i) => {
    return sortedEvents[group].map((event, i) => {
      const date = new Date(Date.parse(event.date));
      const time = new Date(Date.parse(event.startTime))
        .toLocaleString("en-GB", {
          hour: "numeric",
          hour12: true,
          timeZone: "UTC",
        })
        .slice(-2);
      const dayOfWeek = date.toLocaleDateString("en-GB", {
        weekday: "short",
        timeZone: "UTC",
      });
      const dateFormatted = formatConferenceDate(date);
      return (
        <div key={`table-${i}`}>
          {i === 0 && (
            <TableHeader key={`table-header-${i}`}>
              <div>{dayOfWeek}</div>
              <div>{dateFormatted}</div>
              <div>{time}</div>
            </TableHeader>
          )}
          {event.type === "session" && (
            <TableSession
              key={`table-session-${i}`}
              findSpeaker={findSpeaker}
              setSpeakerLeft={setSpeakerLeft}
              event={event}
            />
          )}
          {event.type === "keynote" && (
            <TableKeynote
              key={`table-keynote-${i}`}
              findSpeaker={findSpeaker}
              setSpeakerLeft={setSpeakerLeft}
              event={event}
            />
          )}
        </div>
      );
    });
  });

  return (
    <>
      <SEO title={`${conference.title} · Sculpture Poetry`} />
      <PageContainer>
        <ConferenceInfo>
          <article className='description'>
            {htmlToReactParser.parse(conference.description)}
          </article>
          {!dateHasPassed && (
            <a href={conference.button.value} className='button'>
              {conference.button.customText}
            </a>
          )}
          <article className='location-time'>
            <span className='location-time-date'>
              {formatConferenceDateRange(startDate, endDate)}
            </span>
            <span className='date-circle' />
            <span className='location-time-time'>
              {formatTime(startDate, endDate)}
              <span className='gmt'>GMT</span>
            </span>
            <br />
            {htmlToReactParser.parse(conference.location)}
          </article>
        </ConferenceInfo>
        <BylineContainer>
          {htmlToReactParser.parse(conference.byline)}
        </BylineContainer>
        {timeTables}
      </PageContainer>
      <Footer />
    </>
  );
};

export default ConferencePage;

export const query = graphql`
  query ConferenceQuery($id: String!) {
    conference(id: { eq: $id }) {
      id
      conferenceIndex
      slug
      title
      button {
        customText
        value
      }
      description
      endDate
      startDate
      location
      byline
      events {
        slug
        date
        description
        endTime
        startTime
        eventLink {
          customText
          value
        }
        id
        image {
          url
        }
        speakers {
          name
          slug
        }
        title
        type
      }
    }
  }
`;
