import React, { useEffect, useState } from "react";
import { useGlobalQuery } from "../hooks/useGlobalQuery";
import Cookies from "universal-cookie";
import Menu from "./Menu";
import Header from "./Header";
import "normalize.css";
import "./layout.css";
import SpeakerLeft from "./SpeakerLeft";
import SpeakerRight from "./SpeakerRight";
import Helmet from "react-helmet";
import { Global, css } from "@emotion/react";
import CookieBanner from "./CookieBanner";
import variables from "../variables";

const cookies = new Cookies();

export default function Layout({ children, location }) {
  const data = useGlobalQuery();
  const [showMenu, setShowMenu] = useState(null);
  const [pageType, setPageType] = useState("");
  const [headerInfo, setHeaderInfo] = useState({
    preLink: "",
    preTitle: "",
    parentLink: "",
    parentTitle: "",
    title: "",
    date: "",
  });
  const [speakers, setSpeakers] = useState(data.allSpeaker.nodes);
  const [speakerLeft, setSpeakerLeft] = useState({
    currentSpeaker: null,
    showPanel: false,
  });
  const [speakerRight, setSpeakerRight] = useState({
    currentSpeaker: null,
    showPanel: false,
  });
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    if (cookies.get("acceptedCookies")) {
      setShowCookie(false);
    } else {
      setShowCookie(true);
    }
  }, []);

  const findSpeaker = (slug) => {
    return speakers.find((sp) => sp.slug === slug);
  };

  const childrenWithProps = React.Children.map(children, (child, i) => {
    return React.cloneElement(child, {
      showMenu,
      setShowMenu,
      speakerLeft,
      setSpeakerLeft,
      speakerRight,
      setSpeakerRight,
      headerInfo,
      setHeaderInfo,
      findSpeaker,
      pageType,
      setPageType,
    });
  });

  return (
    <>
      <Global
        styles={css`
          body {
            overflow-y: ${showMenu ? "hidden" : "scroll"};
          }
        `}
      />
      <Helmet>
        <meta name='pinterest' content='nopin' />
      </Helmet>
      {showCookie && (
        <CookieBanner
          isHome={location.pathname === "/"}
          setShowCookie={setShowCookie}
        />
      )}
      {location.pathname !== "/" && (
        <Header
          headerInfo={headerInfo}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          location={location}
          pageType={pageType}
        />
      )}
      {childrenWithProps}
      {(location.pathname === "/" || showMenu) && (
        <>
          {data.global.strapline && (
            <div
              css={css`
                background: var(--black);
                color: var(--white);
                padding: 0.8rem 2rem;
                font-size: 1.25rem;
                text-align: right;
                @media screen and (max-width: ${variables.bpSmall}px) {
                  text-align: center;
                }
              `}
            >
              {data.global.strapline}
            </div>
          )}
          <Menu
            setShowMenu={setShowMenu}
            menuItems={data.global.menuItems}
            aboutText={data.global.aboutText}
            logos={data.global.logos}
            isHome={location.pathname === "/"}
            showMenu={showMenu}
          />
        </>
      )}
      {speakerLeft.showPanel && speakerLeft.currentSpeaker && (
        <SpeakerLeft
          currentSpeaker={speakerLeft.currentSpeaker}
          setSpeakerLeft={setSpeakerLeft}
        />
      )}
      {speakerRight.showPanel && speakerRight.currentSpeaker && (
        <SpeakerRight
          currentSpeaker={speakerRight.currentSpeaker}
          setSpeakerRight={setSpeakerRight}
        />
      )}
    </>
  );
}
