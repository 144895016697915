import React from "react";
import styled from "@emotion/styled";
import Resource from "./Resource";
import variables from "../variables";

const ResourceSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 3rem 1rem;
  @media screen and (max-width: ${variables.bpSmall}px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export default function ResourceSection({ resources }) {
  return (
    <ResourceSectionContainer>
      {resources.map((res, i) => {
        return <Resource key={`resource-${i}`} res={res} />;
      })}
    </ResourceSectionContainer>
  );
}
