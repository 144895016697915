import { useStaticQuery, graphql } from "gatsby";

export const useAboutQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      about {
        leadText
        body
      }
      global {
        logos {
          url
          title
          width
          height
          logoLink
        }
      }
    }
  `);
  return data;
};
