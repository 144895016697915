import styled from "@emotion/styled";
import React, { useEffect } from "react";
import ResourceSection from "../components/ResourceSection";
import SEO from "../components/SeoComp";
import { useResourceQuery } from "../hooks/useResourceQuery";

const PageContainer = styled.div`
  padding: 2rem 1rem 4rem;
  background: var(--light-light-clay);
`;

export default function ResourcePage({ setHeaderInfo }) {
  const resources = useResourceQuery();
  useEffect(() => {
    setHeaderInfo({
      parentLink: "",
      parentTitle: "",
      title: "Resources",
      date: "",
    });
  }, [setHeaderInfo]);
  return (
    <>
      <SEO title='Resources · Sculpture Poetry' />
      <PageContainer>
        <ResourceSection resources={resources} />
      </PageContainer>
    </>
  );
}
