import React, { useState } from "react";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import variables from "../variables";
import { formatTime } from "../helpers";
import { Link } from "gatsby";

const htmlToReactParser = new Parser();

const TableKeynoteContainer = styled.div`
  position: relative;
  ${variables.conferenceGrid};
  background: ${(props) =>
    props.isButtonHover ? "transparent" : "var(--blue)"};
  color: var(--white);
  padding: 1.5rem 1rem 1rem;
  .keynote-time {
    display: flex;
    opacity: ${(props) => (props.isButtonHover ? 0 : 1)};
    .time {
      flex-shrink: 0;
    }
    .time-circle {
      width: 100%;
      display: flex;
      ::after {
        display: inline-block;
        content: "";
        height: calc(1rem + 1px);
        width: calc(1rem + 1px);
        background: var(--white);
        margin: 0 auto;
        align-self: center;
        border-radius: 50%;
        transform: translateX(0.5rem);
      }
    }
  }
  .keynote-label {
    text-transform: uppercase;
    padding: 0 15px;
    opacity: ${(props) => (props.isButtonHover ? 0 : 1)};
  }
  .keynote-title {
    grid-column: span 3;
    margin: 1rem 0;
    text-transform: uppercase;
    opacity: ${(props) => (props.isButtonHover ? 0 : 1)};
    max-width: 42rem;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 0.08em;
      text-underline-offset: 0.16em;
    }
  }
  .button {
    margin-right: auto;
    &:hover {
      color: var(--blue);
      background: var(--white);
      border-color: var(--white);
    }
  }
  .description {
    margin-top: 2rem;
    max-width: 42rem;
    opacity: ${(props) => (props.isButtonHover ? 0 : 1)};
    p {
      :first-of-type {
        padding-top: 1rem;
        margin-bottom: 0.5em;
      }
      :not(:first-of-type):not(:last-child) {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }
    .speakers {
      margin-top: 2rem;
      div {
        cursor: pointer;
        display: inline-block;
        margin-right: 0.8rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  ::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    filter: url("#keynote-filter");
    opacity: ${(props) => (props.isButtonHover ? 1 : 0)};
    background-color: black;
    background-image: ${(props) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : "transparent"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -5;
  }
  @media screen and (max-width: 550px) {
    .keynote-time {
      grid-column: 1/-2;
    }
    .keynote-label {
      grid-column: 3/-1;
      padding-right: 0;
      text-align: right;
    }
  }
`;

export default function TableKeynote({ event, findSpeaker, setSpeakerLeft }) {
  const [isButtonHover, setIsButtonHover] = useState(false);
  const startTime = new Date(Date.parse(event.startTime));
  const endTime = new Date(Date.parse(event.endTime));
  const dateHasPassed = new Date(Date.parse(event.date)) - Date.now() < 0;
  return (
    <TableKeynoteContainer
      isButtonHover={isButtonHover}
      backgroundImage={event.image.url}
    >
      <div className='keynote-time'>
        <span className='time'>{formatTime(startTime, endTime)}</span>
        <span className='time-circle' />
      </div>
      <div className='keynote-label'>Keynote</div>
      <Link className='keynote-title' to={event.slug}>
        <h1>{event.title}</h1>
      </Link>
      {!dateHasPassed && event.eventLink.value && (
        <a
          className='button'
          href={event.eventLink.value}
          target='_blank'
          rel='noopener noreferrer'
          onMouseEnter={() => {
            setIsButtonHover(!isButtonHover);
          }}
          onMouseLeave={() => {
            setIsButtonHover(!isButtonHover);
          }}
        >
          {event.eventLink.customText}
        </a>
      )}
      {dateHasPassed && (
        <Link className='button' to={event.slug}>
          {event.eventLink.customText}
        </Link>
      )}
      {event.description && (
        <div className='description' style={{ gridColumn: "span 3" }}>
          {htmlToReactParser.parse(event.description)}
          {event.speakers.length > 0 && (
            <div className='speakers' style={{ gridColumn: "span 3" }}>
              {event.speakers.map((s, i) => (
                <div
                  key={`speaker-${i}`}
                  role='button'
                  tabIndex={0}
                  onClick={() => {
                    const speaker = findSpeaker(s.slug);
                    if (speaker) {
                      setSpeakerLeft({
                        currentSpeaker: speaker,
                        showPanel: true,
                      });
                    }
                  }}
                  onKeyDown={() => {
                    const speaker = findSpeaker(s.slug);
                    if (speaker) {
                      setSpeakerLeft({
                        currentSpeaker: speaker,
                        showPanel: true,
                      });
                    }
                  }}
                >
                  {s.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <svg className='visually-hidden' xmlns='http://www.w3.org/2000/svg'>
        <filter xmlns='http://www.w3.org/2000/svg' id='keynote-filter'>
          <feColorMatrix
            type='matrix'
            result='grayscale'
            values='1 0 0 0 0               1 0 0 0 0               1 0 0 0 0               0 0 0 1 0'
          ></feColorMatrix>
          <feComponentTransfer
            colorInterpolationFilters='sRGB'
            result='duotone'
          >
            <feFuncR type='table' tableValues='0.25390625 0.98046875' />
            <feFuncG type='table' tableValues='0.40625 0.9453125' />
            <feFuncB type='table' tableValues='0.94140625 0.9375' />
            <feFuncA type='table' tableValues='0 1' />
          </feComponentTransfer>
        </filter>
      </svg>
    </TableKeynoteContainer>
  );
}
