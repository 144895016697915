import React from "react";
import styled from "@emotion/styled";

const FooterContainer = styled.footer`
  width: 100%;
  height: 10px;
  border-bottom: 10px solid var(--clay);
`;

export default function Footer() {
  return <FooterContainer />;
}
