import React from "react";
import { Link } from "gatsby";
import { Icon } from "@iconify/react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import variables from "../variables";

const HeaderContainer = styled.div`
  width: 100%;
  .header-logo {
    padding: 1rem 1rem 0;
  }
  .header-crumbs {
    padding: 0 1rem 0;
    h3 {
      margin-top: 0.85em;
      margin-bottom: 0.85em;
    }
    .pre-parent {
      margin-right: 0.3em;
    }
    .parent {
      margin-right: 0.3em;
      @media screen and (max-width: ${variables.bpSmall}px) {
        display: none;
      }
    }
    .pre-title {
      margin-right: 0.3em;
    }
    .title:not(:first-of-type) {
      margin-left: 0.3em;
      color: var(--black);
    }
  }
  &.is-conference {
    .header-logo {
      &::after {
        content: "";
        display: block;
        height: 2px;
        background: none;
        width: 100%;
      }
    }
    .header-crumbs {
      color: var(--white);
      background: var(--clay);
    }
    @media screen and (max-width: 350px) {
      .pre-title {
        display: none;
      }
    }
  }
  &.is-public-event {
    .pre-title {
      color: var(--clay);
    }
  }
  &.is-keynote {
    .header-date {
      color: var(--clay);
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: "";
    display: block;
    height: 2px;
    background: var(--black);
    width: 100%;
  }
  h1 {
    margin: 0;
    color: var(--clay);
  }
  .menu-button {
    z-index: 100;
    color: ${(props) => (props.showMenu ? "var(--white)" : "var(--black)")};
    position: ${(props) => (props.showMenu ? "fixed" : "relative")};
    top: ${(props) => (props.showMenu ? "1rem" : "0")};
    right: ${(props) => (props.showMenu ? "1rem" : "0")};
    &:hover {
      background: ${(props) =>
        props.showMenu ? "var(--white)" : "var(--black)"};
      border-color: ${(props) =>
        props.showMenu ? "var(--white)" : "var(--black)"};
      color: ${(props) => (props.showMenu ? "var(--clay)" : "var(--white)")};
    }
    @media screen and (max-width: 850px) {
      left: ${(props) => (props.showMenu ? "1rem" : "0")};
      margin: ${(props) => (props.showMenu ? "0" : "0")};
      background: ${(props) =>
        props.showMenu ? "var(--clay)" : "var(--white)"};
    }
    @media screen and (max-width: 350px) {
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      position: relative;
      margin-bottom: 2rem;
    }
  }
  .icon {
    margin-right: -0.3em;
    transform: translateX(-0.35rem) translateY(0.4rem);
    color: var(--black);
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BreadCrumb = styled.div`
  a {
    color: var(--clay);
    margin-right: 0.3rem;
  }
  a,
  h3 {
    display: inline-block;
  }
`;

export default function Header({
  headerInfo,
  showMenu,
  setShowMenu,
  location,
  pageType,
}) {
  let { preParent, parentLink, parentTitle, preTitle, title, date } =
    headerInfo;
  if (preParent === "<-") {
    preParent = (
      <Icon
        className='icon'
        icon='bi:arrow-left-short'
        style={{ fontSize: "1.5rem" }}
      />
    );
  }
  if (parentTitle === "<-") {
    parentTitle = (
      <Icon
        className='icon'
        icon='bi:arrow-left-short'
        style={{ fontSize: "1.5rem" }}
      />
    );
  }
  return (
    <HeaderContainer className={pageType.length && `is-${pageType}`}>
      <HeaderRow className='header-logo' showMenu={showMenu}>
        <FlexContainer
          css={css`
            margin-bottom: 1rem;
            align-items: center;
            @media screen and (max-width: ${variables.bpXXXSmall}px) {
              flex-direction: column-reverse;
            }
          `}
        >
          <Link to='/'>
            <h1>Sculpture & Poetry</h1>
          </Link>
          <div
            role='button'
            className='button menu-button'
            tabIndex={0}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            onKeyDown={() => {
              setShowMenu(!showMenu);
            }}
          >
            {showMenu ? "Close" : "Contents"}
          </div>
        </FlexContainer>
      </HeaderRow>
      {(parentLink || title) && (
        <HeaderRow className='header-crumbs'>
          <FlexContainer
            css={css`
              @media screen and (max-width: ${variables.bpSmall}px) {
                flex-direction: column;
                align-items: flex-start;
                .header-date h3 {
                  margin-top: -0.5rem;
                }
              }
            `}
          >
            <BreadCrumb className='parent-container'>
              {preParent && <span className='pre-parent'>{preParent}</span>}
              {parentLink && (
                <Link className='parent' to={parentLink}>
                  {parentTitle}
                </Link>
              )}
              {title && (
                <h3>
                  {preTitle && <span className='pre-title'>{preTitle}</span>}
                  <span className='title'>{title}</span>
                </h3>
              )}
            </BreadCrumb>
            <div className='header-date'>{date && <h3>{date}</h3>}</div>
          </FlexContainer>
        </HeaderRow>
      )}
    </HeaderContainer>
  );
}
