import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { formatMonthYear } from "../helpers";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import variables from "../variables";
import ResourceSection from "../components/ResourceSection";
import { useHtmlProcessor } from "../hooks/useHtmlProcessorHook";
import Footer from "../components/Footer";
import SEO from "../components/SeoComp";

const htmlToReactParser = new Parser();

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.618fr;
  padding: 2rem 1rem 6rem;
  .keynote-title {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 1.5rem;
  }
  .page-body {
    figure {
      figcaption {
        color: var(--dark-clay);
      }
    }
    p {
      color: var(--dark-clay);
    }
    ul,
    ol {
    }
    li {
      padding-left: 0.3em;
      margin-bottom: 0.5em;
    }
  }
  ${variables.pageBody};
`;

const ResourcesContainer = styled.section`
  padding: 2rem 1rem 4rem;
  background: var(--light-light-clay);
  .resources-title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    h3 {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: var(--clay);
      background: var(--light-light-clay);
      display: inline-block;
      padding-right: 0.5rem;
      flex-shrink: 0;
    }
    .title-line {
      display: inline-block;
      height: 2px;
      width: 100%;
      background: var(--white);
    }
  }
`;

const Page = ({ data, setHeaderInfo, setShowMenu, setPageType }) => {
  const { page } = data;
  const { resources } = page;

  useEffect(() => {
    setShowMenu(false);
    setPageType("page");
    setHeaderInfo({
      preParent: "",
      parentLink: "",
      parentTitle: "",
      preTitle: "",
      title: page.title,
      date: "",
    });
    return () => {
      setPageType("");
      setHeaderInfo({
        parentLink: "",
        parentTitle: "",
        preTitle: "",
        title: "",
        date: "",
      });
    };
  }, [page.title, setHeaderInfo, setPageType, setShowMenu]);

  return (
    <>
      <SEO title={`${page.title} · Sculpture Poetry`} />
      <PageContainer>
        <div className='lead-text'>
          {htmlToReactParser.parse(page.leadText)}
        </div>
        <div className='page-body'>{useHtmlProcessor(page.body)}</div>
      </PageContainer>
      {resources.length && (
        <ResourcesContainer>
          <div className='resources-title'>
            <h3>Related Resources</h3>
            <span className='title-line' />
          </div>
          <ResourceSection resources={resources} />
        </ResourcesContainer>
      )}
      <Footer />
    </>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($id: String!) {
    page(id: { eq: $id }) {
      id
      slug
      title
      leadText
      body
      resources {
        category
        link {
          value
        }
        image {
          url
          caption
        }
        text
        title
      }
    }
  }
`;
