import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { formatConferenceDate, formatTime } from "../helpers";
import { Parser } from "html-to-react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import variables from "../variables";
import { useHtmlProcessor } from "../hooks/useHtmlProcessorHook";
import ResourceSection from "../components/ResourceSection";
import Footer from "../components/Footer";
import SEO from "../components/SeoComp";

const htmlToReactParser = new Parser();

const SpeakersBar = styled.div`
  margin: 1rem 1rem 0;
  padding-bottom: 0.9rem;
  border-bottom: 2px solid var(--black);
  color: var(--blue);
  div:not(:last-child) {
    margin-right: 0.8rem;
  }
  div:hover {
    text-decoration: underline;
  }
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.618fr;
  padding: 2rem 1rem 6rem;
  background: var(--clay);
  .button {
    grid-column: 1/-1;
    background: var(--blue);
    color: var(--white);
    border-color: var(--blue);
    margin-right: auto;
    &:hover {
      background: var(--white);
      border-color: var(--white);
      color: var(--blue);
    }
  }
  .lead-text {
    color: var(--white);
  }
  .page-body {
    figure {
      figcaption {
        color: var(--white);
      }
    }
    p {
      color: var(--white);
    }
  }
  ${variables.pageBody};
`;

const C8Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  padding: 1rem 1rem 1rem;
  background: var(--black);
  color: var(--white);
  .c8-label {
    text-transform: uppercase;
    grid-column: 1/-1;
    .circle {
      display: inline-block;
      height: calc(1rem);
      width: calc(1rem);
      background: var(--white);
      margin: 0 auto;
      align-self: center;
      border-radius: 50%;
      margin: 0 1rem;
      transform: translateY(0.1em);
    }
  }
  .c8-title {
    grid-column: 1/-1;
    margin: 1rem 0;
    max-width: 42rem;
    text-transform: uppercase;
  }
  .button {
    grid-column: 1/-1;
    margin-right: auto;
    &:hover {
      background: var(--white);
      color: var(--black);
      border-color: var(--white);
    }
  }
  .c8-bio {
    grid-column: 1/-1;
    margin-top: 3rem;
    max-width: 42rem;
    line-height: 1.2;
  }
  .c8-caption {
    grid-column: 1/-1;
    margin-top: 1rem;
    font-size: 0.8rem;
  }
`;

const ResourcesContainer = styled.section`
  margin: 2rem 1rem 4rem;
  .resources-title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    h3 {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: var(--clay);
      background: var(--white);
      display: inline-block;
      padding-right: 0.5rem;
      flex-shrink: 0;
    }
    .title-line {
      display: inline-block;
      height: 2px;
      width: 100%;
      background: var(--light-clay);
    }
  }
`;

const PublicEventPage = ({
  data,
  pageContext,
  setHeaderInfo,
  setShowMenu,
  findSpeaker,
  speakerLeft,
  setSpeakerLeft,
  speakerRight,
  setSpeakerRight,
  setPageType,
}) => {
  const { publicEvent } = data;
  const { speakers, resources, writingInfo } = publicEvent;

  useEffect(() => {
    setShowMenu(false);
    setPageType("public-event");
    setHeaderInfo({
      preParent: "",
      parentLink: `/`,
      parentTitle: `<-`,
      preTitle: `Public Event`,
      title: publicEvent.title,
      date: `${formatConferenceDate(
        new Date(Date.parse(publicEvent.date))
      )}${htmlToReactParser.parse("&ensp;")}${formatTime(
        new Date(Date.parse(publicEvent.startTime)),
        new Date(Date.parse(publicEvent.endTime))
      )}`,
    });
    return () => {
      setPageType("");
      setHeaderInfo({
        parentLink: "",
        parentTitle: "",
        preTitle: "",
        title: "",
        date: "",
      });
    };
  }, [
    publicEvent.title,
    publicEvent.date,
    publicEvent.startTime,
    publicEvent.endTime,
    setHeaderInfo,
    setPageType,
    setShowMenu,
  ]);

  return (
    <>
      <SEO title={`${publicEvent.title} · Sculpture Poetry`} />
      <SpeakersBar
        style={{
          display: "flex",
          justifyContent:
            speakers.length === 2 ? "space-between" : "flex-start",
        }}
      >
        {speakers.length &&
          speakers.map((s, i) => (
            <div
              key={`speaker-${i}`}
              role='button'
              tabIndex={0}
              style={{ cursor: "pointer" }}
              onClick={() => {
                const speaker = findSpeaker(s.slug);
                if (speakers.length > 2 && speaker) {
                  setSpeakerLeft({
                    currentSpeaker: speaker,
                    showPanel: true,
                  });
                } else {
                  if (i === 0 && speaker) {
                    setSpeakerLeft({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  } else if (i === 1 && speaker) {
                    setSpeakerRight({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  }
                }
              }}
              onKeyDown={() => {
                const speaker = findSpeaker(s.slug);
                if (speakers.length > 2 && speaker) {
                  setSpeakerLeft({
                    currentSpeaker: speaker,
                    showPanel: true,
                  });
                } else {
                  if (i === 0 && speaker) {
                    setSpeakerLeft({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  } else if (i === 1 && speaker) {
                    setSpeakerRight({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  }
                }
              }}
            >
              {s.name}
            </div>
          ))}
      </SpeakersBar>
      <PageContainer>
        {publicEvent.eventLink.value && (
          <a
            className='button'
            href={publicEvent.eventLink.value}
            target='_blank'
            rel='noreferrer noopener'
          >
            {publicEvent.eventLink.customText
              ? publicEvent.eventLink.customText
              : "Book Tickets"}
          </a>
        )}
        <div className='lead-text'>
          {htmlToReactParser.parse(publicEvent.leadText)}
        </div>
        <div className='page-body'>{useHtmlProcessor(publicEvent.body)}</div>
      </PageContainer>
      {writingInfo.title?.length && (
        <C8Container>
          <div className='c8-label'>
            Commissioned Writing
            <span className='circle' />
            Corridor8
          </div>
          <h1 className='c8-title'>{writingInfo.title}</h1>
          {writingInfo.button.value.length && (
            <a
              className='button'
              href={writingInfo.button.value}
              target='_blank'
              rel='noopener noreferrer'
            >
              {writingInfo.button.customText}
            </a>
          )}
          {writingInfo.bio.length && (
            <div className='c8-bio'>
              {htmlToReactParser.parse(writingInfo.bio)}
            </div>
          )}
          {writingInfo.caption.length && (
            <div className='c8-caption'>
              {htmlToReactParser.parse(writingInfo.caption)}
            </div>
          )}
        </C8Container>
      )}
      {resources.length && (
        <ResourcesContainer>
          <div className='resources-title'>
            <h3>Related Resources</h3>
            <span className='title-line' />
          </div>
          <ResourceSection resources={resources} />
        </ResourcesContainer>
      )}
      <Footer />
    </>
  );
};

export default PublicEventPage;

export const query = graphql`
  query publicEventQuery($id: String!) {
    publicEvent(id: { eq: $id }) {
      id
      slug
      leadText
      body
      title
      date
      startTime
      endTime
      eventLink {
        customText
        value
      }
      speakers {
        name
        slug
        uid
        url
      }
      resources {
        category
        link {
          type
          value
        }
        resourceLink
        image {
          url
          caption
        }
        text
        title
      }
      writingInfo {
        title
        bio
        caption
        button {
          customText
          value
        }
      }
    }
  }
`;
