export const formatMonthYear = (date) => {
  let fmt = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    timeZone: "UTC",
  });

  return fmt.format(date);
};

export const formatConferenceDate = (date) => {
  let fmt = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  return fmt.format(date);
};

export const formatConferenceDateRange = (startDate, endDate) => {
  let fmt = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

  return fmt.formatRange(startDate, endDate);
};

export const formatTime = (startTime, endTime) => {
  let fmt = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const timeString = `${fmt.format(startTime)}–${fmt.format(endTime)}`;

  return timeString;
};
