import React, { useState } from "react";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import variables from "../variables";
import { useRef } from "react";
import { useEffect } from "react";

const htmlToReactParser = new Parser();

const ResourceContainer = styled.a`
  max-width: 22rem;
  h2 {
    color: var(--blue);
    margin-bottom: 0.5rem;
  }
  h3 {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    color: var(--blue);
  }
  h6 {
    margin-top: 0;
    margin-bottom: 0.4rem;
    text-transform: uppercase;
    color: ${(props) =>
      props.category === "corridor8" ? "var(--blue)" : "var(--dark-clay)"};
    font-size: 0.5rem;
    letter-spacing: 0.05em;
  }
  img {
    width: 100%;
    filter: ${(props) =>
      props.category === "corridor8"
        ? 'url("#resource-filter-blue")'
        : 'url("#resource-filter-clay")'};
    opacity: ${(props) => (props.imageLoaded ? 1 : 0)};
    transition: opacity 1s;
    + h3 {
      margin-top: 0.4rem;
    }
  }
  p {
    color: ${(props) =>
      props.category === "corridor8" ? "var(--blue)" : "var(--dark-clay)"};
    font-size: 0.65rem;
    line-height: 1.2;
    + img {
      margin-top: 0.5rem;
    }
  }
  &:hover {
    h2,
    h3 {
      text-decoration: underline;
    }
    img {
      filter: contrast(1.1) brightness(0.98);
    }
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    max-width: initial;
    h3 {
      font-size: 1rem;
    }
    h6 {
      font-size: 0.625rem;
    }
    p {
      font-size: 0.8125rem;
    }
  }
`;

export default function Resource({ res }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef();
  const { title, text, image, link, category, id, resourceLink } = res;
  const newUrl = image?.url?.includes("https://cms.sculpture-poetry.net")
    ? image.url
    : `https://cms.sculpture-poetry.net${image.url}`;

  useEffect(() => {
    if (imageRef?.current?.complete) {
      setImageLoaded(true);
    }
  }, []);

  return (
    <ResourceContainer
      key={id}
      href={
        link.type !== "asset"
          ? link.value
          : `https://cms.sculpture-poetry.net${resourceLink}`
      }
      target='_blank'
      rel='noopener noreferrer'
      category={category && category.toLowerCase()}
      imageLoaded={imageLoaded}
    >
      {category && <h6>{category}</h6>}
      {category && category.toLowerCase() === "watch" && image.url && (
        <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          ref={imageRef}
          src={newUrl}
          alt={image.caption}
        />
      )}
      {image.url ? <h3>{title}</h3> : <h2>{title}</h2>}
      {text && htmlToReactParser.parse(text)}
      {category && category.toLowerCase() !== "watch" && image.url && (
        <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          ref={imageRef}
          src={newUrl}
          alt={image.caption}
        />
      )}
      <svg className='visually-hidden' xmlns='http://www.w3.org/2000/svg'>
        <filter xmlns='http://www.w3.org/2000/svg' id='resource-filter-clay'>
          <feColorMatrix
            type='matrix'
            result='grayscale'
            values='1 0 0 0 0               1 0 0 0 0               1 0 0 0 0               0 0 0 1 0'
          ></feColorMatrix>
          <feComponentTransfer
            colorInterpolationFilters='sRGB'
            result='duotone'
          >
            <feFuncR type='table' tableValues='0.828125 0.98046875' />
            <feFuncG type='table' tableValues='0.48046875 0.9453125' />
            <feFuncB type='table' tableValues='0.4296875 0.9375' />
            <feFuncA type='table' tableValues='0 1' />
          </feComponentTransfer>
        </filter>
        <filter xmlns='http://www.w3.org/2000/svg' id='resource-filter-blue'>
          <feColorMatrix
            type='matrix'
            result='grayscale'
            values='1 0 0 0 0               1 0 0 0 0               1 0 0 0 0               0 0 0 1 0'
          ></feColorMatrix>
          <feComponentTransfer
            colorInterpolationFilters='sRGB'
            result='duotone'
          >
            <feFuncR type='table' tableValues='0.25390625 0.98046875' />
            <feFuncG type='table' tableValues='0.40625 0.9453125' />
            <feFuncB type='table' tableValues='0.94140625 0.9375' />
            <feFuncA type='table' tableValues='0 1' />
          </feComponentTransfer>
        </filter>
      </svg>
    </ResourceContainer>
  );
}
