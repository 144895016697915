import React, { useRef, useState } from "react";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import variables from "../variables";
import { formatTime } from "../helpers";

const htmlToReactParser = new Parser();

const TableSessionContainer = styled.div`
  ${variables.conferenceGrid};
  margin: 1rem;
  .time {
    cursor: pointer;
  }
  h3 {
    padding: 0 15px;
    margin: 0;
    color: var(--blue);
    text-transform: uppercase;
    cursor: pointer;
  }
  .dropdown-button {
    color: var(--blue);
    cursor: pointer;
    transform: translateY(-0.25em);
  }
  .description {
    padding-bottom: 1rem;
    p {
      :first-of-type {
        padding-top: 1rem;
        margin-bottom: 0.5em;
      }
      :not(:first-of-type):not(:last-child) {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      color: var(--dark-clay);
    }
    .speakers {
      margin-top: 2rem;
      color: var(--blue);
      div {
        cursor: pointer;
        display: inline-block;
        margin-right: 0.8rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    h3 {
      grid-column: 1/-1;
      padding-left: 0;
      margin-bottom: 0.8rem;
    }
    .dropdown-button {
      grid-row: 1;
      grid-column: -2/-1;
    }
  }
`;

export default function TableSession({ event, findSpeaker, setSpeakerLeft }) {
  const [isOpen, setIsOpen] = useState(false);
  const textArea = useRef(null);
  return (
    <TableSessionContainer>
      <div
        className='time'
        role='button'
        tabIndex={0}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
      >
        {formatTime(
          new Date(Date.parse(event.startTime)),
          new Date(Date.parse(event.endTime))
        )}
      </div>
      <h3
        role='button'
        tabIndex={0}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
      >
        {event.title}
      </h3>
      <div
        className='dropdown-button'
        role='button'
        tabIndex={0}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
        style={{ textAlign: "right" }}
      >
        {isOpen ? (
          <Icon icon='mdi:chevron-up' style={{ fontSize: "1.3rem" }} />
        ) : (
          <Icon icon='mdi:chevron-down' style={{ fontSize: "1.3rem" }} />
        )}
      </div>
      {event.description && (
        <div
          style={{
            gridColumn: "span 3",
            height: isOpen ? `${textArea.current.clientHeight}px` : "0px",
            overflow: "hidden",
            transition: "height 1s",
            borderBottom: isOpen
              ? "2px solid var(--light-light-clay)"
              : "2px solid var(--light-light-clay)",
          }}
        >
          <div className='description' ref={textArea}>
            {htmlToReactParser.parse(event.description)}
            {event.speakers.length > 0 && (
              <div className='speakers' style={{ gridColumn: "span 3" }}>
                {event.speakers.map((s, i) => (
                  <div
                    key={`speaker-${i}`}
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                      const speaker = findSpeaker(s.slug);
                      if (speaker) {
                        setSpeakerLeft({
                          currentSpeaker: speaker,
                          showPanel: true,
                        });
                      }
                    }}
                    onKeyDown={() => {
                      const speaker = findSpeaker(s.slug);
                      if (speaker) {
                        setSpeakerLeft({
                          currentSpeaker: speaker,
                          showPanel: true,
                        });
                      }
                    }}
                  >
                    {s.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </TableSessionContainer>
  );
}
