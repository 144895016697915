import React, { useState, useEffect, useRef } from "react";

export default function Image({ src, alt }) {
  const imageRef = useRef();
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    if (imageRef?.current?.complete) {
      setImageLoaded(true);
    }
  }, []);
  return (
    <img
      ref={imageRef}
      style={{ opacity: imageLoaded ? 1 : 0, transition: "opacity 1s" }}
      onLoad={() => {
        setImageLoaded(true);
      }}
      src={src}
      alt={alt}
    />
  );
}
