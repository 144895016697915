import React, { useEffect } from "react";
import { Parser } from "html-to-react";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import { useAboutQuery } from "../hooks/useAboutQuery";
import { useHtmlProcessor } from "../hooks/useHtmlProcessorHook";
import styled from "@emotion/styled";
import variables from "../variables";
import SEO from "../components/SeoComp";

const htmlToReactParser = new Parser();

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.618fr;
  padding: 2rem 1rem 6rem;
  background: var(--light-light-clay);
  .page-body {
    figure {
      figcaption {
        color: var(--dark-clay);
      }
    }
    p {
      color: var(--dark-clay);
    }
  }
  .logos-container {
    grid-column: 2/-1;
    margin-top: 4rem;
    .logo-link {
      margin-right: 2.2rem;
      :nth-child(2) {
        margin-right: 1.8rem;
      }
    }
    svg {
      fill: var(--dark-clay);
      --width: 0;
      --height: 0;
      --ideal-area: 80000;
      --area: calc(var(--width) * var(--height));
      --ratio: calc(var(--ideal-area) / var(--area));
      --guess01: calc(
        calc(var(--ratio) + calc(var(--ratio) / var(--ratio))) / 2
      );
      --guess02: calc(
        calc(var(--guess01) + calc(var(--ratio) / var(--guess01))) / 2
      );
      --guess03: calc(
        calc(var(--guess02) + calc(var(--ratio) / var(--guess02))) / 2
      );
      --guess04: calc(
        calc(var(--guess03) + calc(var(--ratio) / var(--guess03))) / 2
      );
      --guess05: calc(
        calc(var(--guess04) + calc(var(--ratio) / var(--guess04))) / 2
      );
      --guess06: calc(
        calc(var(--guess05) + calc(var(--ratio) / var(--guess05))) / 2
      );
      --guess07: calc(
        calc(var(--guess06) + calc(var(--ratio) / var(--guess06))) / 2
      );
      --guess08: calc(
        calc(var(--guess07) + calc(var(--ratio) / var(--guess07))) / 2
      );
      max-width: calc(var(--width) * var(--guess08) / 2 * 1px);
      @media screen and (max-width: 1300px) {
        --ideal-area: 30000;
      }
    }
    @media screen and (max-width: ${variables.bpMed}px) {
      display: flex;
      flex-direction: column;
      .logo-link:not(:last-child) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
    @media screen and (max-width: ${variables.bpSmall}px) {
      width: 100%;
      grid-column: 1/-1;
      a {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
  ${variables.pageBody};
`;

export default function AboutPage({ setHeaderInfo }) {
  const data = useAboutQuery();
  const { about, global } = data;
  useEffect(() => {
    setHeaderInfo({
      parentLink: "",
      parentTitle: "",
      title: "About",
      date: "",
    });
  }, [setHeaderInfo]);
  return (
    <>
      <SEO title='About · Sculpture Poetry' />
      <PageContainer>
        <div className='lead-text'>
          {htmlToReactParser.parse(about.leadText)}
        </div>
        <div className='page-body'>{useHtmlProcessor(about.body)}</div>
        <div className='logos-container'>
          {global.logos.map((l) => {
            return (
              <a
                className='logo-link'
                href={l.logoLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SVG
                  style={{ "--width": l.width, "--height": l.height }}
                  cacheRequests={true}
                  loader={<span>Loading...</span>}
                  src={l.url}
                  title={l.title}
                />
              </a>
            );
          })}
        </div>
      </PageContainer>
    </>
  );
}
