import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { formatMonthYear } from "../helpers";
import { Parser } from "html-to-react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import variables from "../variables";
import ResourceSection from "../components/ResourceSection";
import { useHtmlProcessor } from "../hooks/useHtmlProcessorHook";
import Footer from "../components/Footer";
import SEO from "../components/SeoComp";

const htmlToReactParser = new Parser();

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.618fr;
  padding: 2rem 1rem 6rem;
  .page-body {
    figure {
      figcaption {
        color: var(--dark-clay);
      }
    }
    p {
      color: var(--dark-clay);
    }
  }
  ${variables.pageBody};
`;

const KeynoteHeader = styled.div`
  margin: 0 1rem 0;
  .keynote-title {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 1.5rem;
    max-width: 42rem;
  }
`;

const SpeakersBar = styled.div`
  margin: 1rem 0 0;
  padding-bottom: 0.9rem;
  border-bottom: 2px solid var(--black);
  color: var(--blue);
  div:not(:last-child) {
    margin-right: 0.8rem;
  }
  div:hover {
    text-decoration: underline;
  }
`;

const ResourcesContainer = styled.section`
  padding: 2rem 1rem 4rem;
  background: var(--light-light-clay);
  .resources-title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    h3 {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: var(--clay);
      background: var(--light-light-clay);
      display: inline-block;
      padding-right: 0.5rem;
      flex-shrink: 0;
    }
    .title-line {
      display: inline-block;
      height: 2px;
      width: 100%;
      background: var(--white);
    }
  }
`;

const KeynotePage = ({
  data,
  pageContext,
  setHeaderInfo,
  setShowMenu,
  findSpeaker,
  speakerLeft,
  setSpeakerLeft,
  setPageType,
}) => {
  const { keynote } = data;
  const { speakers, resources } = keynote;

  useEffect(() => {
    setShowMenu(false);
    setPageType("keynote");
    setHeaderInfo({
      preParent: "<-",
      parentLink: `/conferences/${keynote.parentLink}`,
      parentTitle: `Conference #${keynote.parentIndex}`,
      preTitle: ``,
      title: keynote.parentTitle,
      date: formatMonthYear(new Date(Date.parse(keynote.date))),
    });
    return () => {
      setPageType("");
      setHeaderInfo({
        parentLink: "",
        parentTitle: "",
        preTitle: "",
        title: "",
        date: "",
      });
    };
  }, [
    keynote.date,
    keynote.parentIndex,
    keynote.parentLink,
    keynote.parentTitle,
    setHeaderInfo,
    setPageType,
    setShowMenu,
  ]);

  return (
    <>
      <SEO title={`${keynote.parentTitle} · Sculpture Poetry`} />
      <KeynoteHeader>
        <h1 className='keynote-title'>{keynote.title}</h1>
        <SpeakersBar
          style={{
            display: "flex",
            justifyContent:
              speakers.length === 2 ? "space-between" : "flex-start",
          }}
        >
          {speakers.length &&
            speakers.map((s, i) => (
              <div
                key={`speaker-${i}`}
                role='button'
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const speaker = findSpeaker(s.slug);
                  if (speaker) {
                    setSpeakerLeft({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  }
                }}
                onKeyDown={() => {
                  const speaker = findSpeaker(s.slug);
                  if (speaker) {
                    setSpeakerLeft({
                      currentSpeaker: speaker,
                      showPanel: true,
                    });
                  }
                }}
              >
                {s.name}
              </div>
            ))}
        </SpeakersBar>
      </KeynoteHeader>
      <PageContainer>
        <div className='lead-text'>
          {htmlToReactParser.parse(keynote.leadText)}
        </div>
        <div className='page-body'>{useHtmlProcessor(keynote.body)}</div>
      </PageContainer>
      {resources.length && (
        <ResourcesContainer>
          <div className='resources-title'>
            <h3>Related Resources</h3>
            <span className='title-line' />
          </div>
          <ResourceSection resources={resources} />
        </ResourcesContainer>
      )}
      <Footer />
    </>
  );
};

export default KeynotePage;

export const query = graphql`
  query KeynoteQuery($id: String!) {
    keynote(id: { eq: $id }) {
      id
      slug
      leadText
      body
      description
      title
      parentTitle
      parentLink
      parentIndex
      date
      startTime
      endTime
      speakers {
        name
        slug
        uid
        url
      }
      resources {
        category
        link {
          value
          type
        }
        resourceLink
        image {
          url
          caption
        }
        text
        title
      }
    }
  }
`;
