import { css } from "@emotion/react";

const variables = {
  bpXXXSmall: 350,
  bpXXSmall: 450,
  bpXSmall: 640,
  bpSmall: 740,
  bpMed: 1024,
  conferenceGrid: css`
    display: grid;
    grid-template-columns: 3fr 6fr 1fr;
    @media screen and (max-width: 550px) {
      grid-template-columns: 3fr 6fr 1fr;
    }
    @media screen and (max-width: 350px) {
      grid-template-columns: 4fr 7fr 1fr;
    }
  `,
  pageBody: css`
    .lead-text {
      grid-column: 1/-1;
      margin: 3rem 0 4rem;
      font-size: 1.5rem;
      line-height: 1.2;
      max-width: 42rem;
    }
    .page-body {
      grid-column: 2/-1;
      figure {
        margin: 0;
        margin-bottom: 3rem;
        &.portrait {
          width: 61.8%;
        }
        img {
          width: 100%;
        }
        figcaption {
          font-size: 0.8rem;
          text-align: center;
          max-width: 32rem;
          line-height: 1.2;
          margin: 0.5rem auto 0;
        }
      }
      a {
        text-decoration: underline;
      }
      blockquote {
        margin-left: 0;
        font-size: 0.8rem;
      }
      h3:not(:first-of-type) {
        margin-top: 3rem;
      }
      p {
        line-height: 1.2;
        + blockquote {
          margin-top: 2rem;
        }
        + figure {
          margin-top: 4rem;
        }
      }
    }
    @media screen and (max-width: 740px) {
      .page-body {
        grid-column: 1/-1;
        figure {
          &.portrait {
            width: 100%;
          }
        }
      }
    }
  `,
};

export default variables;
