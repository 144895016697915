import { useStaticQuery, graphql } from "gatsby";

export const useGlobalQuery = () => {
  const globalData = useStaticQuery(graphql`
    query {
      global {
        aboutText
        strapline
        menuItems {
          title
          startDate
          slug
          url
          type
          isLive
          conferenceIndex
        }
        logos {
          url
          title
          width
          height
          logoLink
        }
      }
      allSpeaker {
        nodes {
          caption
          profilePic {
            url
            alt
          }
          slug
          name
          url
          id
          description
          externals {
            link
            title
          }
        }
      }
    }
  `);
  return globalData;
};
