import { useStaticQuery, graphql } from "gatsby";

export const useResourceQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allResource {
        nodes {
          title
          text
          image {
            url
            caption
          }
          link {
            customText
            type
            value
          }
          resourceLink
          category
          id
        }
      }
    }
  `);
  return data.allResource.nodes;
};
