import React from "react";
import Image from "../components/Image";
const HtmlToReact = require("html-to-react");
const HtmlToReactParser = require("html-to-react").Parser;

const isValidNode = function () {
  return true;
};

// Order matters. Instructions are processed in the order they're defined
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const processingInstructions = [
  {
    shouldProcessNode: function (node) {
      return node.name && node.name === "img";
    },
    processNode: function (node, children) {
      let newSrc = `https://cms.sculpture-poetry.net${node.attribs.src}`;
      node.attribs.src = newSrc;
      return <Image src={newSrc} alt={node.attribs.alt} />;
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name && node.name === "a";
    },
    processNode: function (node, children) {
      return (
        <a href={node.attribs.href} target='_blank' rel='noreferrer noopener'>
          {children}
        </a>
      );
    },
  },
  {
    // Anything else
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];
const htmlToReactParser = new HtmlToReactParser();

export function useHtmlProcessor(html) {
  return htmlToReactParser.parseWithInstructions(
    html,
    isValidNode,
    processingInstructions
  );
}
