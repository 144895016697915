import React, { useEffect, useRef, useState } from "react";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import variables from "../variables";

const htmlToReactParser = new Parser();

const SpeakerHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 1rem;
  width: 100%;
  align-items: flex-start;
  margin-bottom: ${(props) => (props.hasImage ? "4rem" : "2rem")};
  h1 {
    margin: 0;
    margin-left: auto;
  }
`;

const SpeakerRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  padding: 1.5rem 1rem;
  background: var(--blue);
  color: var(--white);
  transform: ${(props) =>
    props.animatePanel ? "translateX(0%)" : "translateX(100%)"};
  transition: transform 1s;
  overflow-y: scroll;
  z-index: 100;
  img {
    width: 60%;
    margin: 0 auto;
    opacity: ${(props) => (props.imageLoaded ? 1 : 0)};
    transition: opacity 1s;
    margin-bottom: 4rem;
  }
  p {
    line-height: 1.2;
    :first-of-type {
      margin-top: 0;
    }
    :not(:first-of-type):not(:last-child) {
      margin-top: 0.1em;
      margin-bottom: 0.1em;
    }
    :last-child {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .button {
    color: var(--black);
    background: white;
    &:hover {
      color: var(--white);
      background: var(--black);
      border-color: var(--black);
    }
  }
  .external-links {
    list-style: none;
    padding: 0;
    margin-top: 3rem;
    font-size: 0.8rem;
    a {
      text-decoration: underline;
      line-height: 1.4;
    }
  }
  .speaker-caption {
    margin-top: 1rem;
    font-size: 0.8rem;
    max-width: 16rem;
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    width: 90%;
  }
  :focus {
    outline: none;
  }
`;

export default function SpeakerRight({ currentSpeaker, setSpeakerRight }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [animatePanel, setAnimatePanel] = useState(false);
  const speakerPanel = useRef(null);
  const url = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    setAnimatePanel(true);
    speakerPanel.current.focus();
  }, []);

  return (
    <SpeakerRightContainer
      animatePanel={animatePanel}
      imageLoaded={imageLoaded}
      ref={speakerPanel}
      tabIndex='0'
      onBlur={() => {
        if (!url.includes("public-event")) {
          setAnimatePanel(false);
          setTimeout(() => {
            setSpeakerRight({ currentSpeaker: null, showPanel: false });
          }, 1000);
        }
      }}
    >
      <SpeakerHeader hasImage={currentSpeaker.profilePic.url}>
        <span
          className='button'
          role='button'
          tabIndex={0}
          onClick={() => {
            setAnimatePanel(false);
            setTimeout(() => {
              setSpeakerRight({ currentSpeaker: null, showPanel: false });
            }, 1000);
          }}
          onKeyDown={() => {
            setAnimatePanel(false);
            setTimeout(() => {
              setSpeakerRight({ currentSpeaker: null, showPanel: false });
            }, 1000);
          }}
        >
          Close
        </span>
        <h1>{currentSpeaker.name}</h1>
      </SpeakerHeader>
      {currentSpeaker.profilePic.url && (
        <img
          alt={currentSpeaker.profilePic.alt}
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={currentSpeaker.profilePic.url}
        />
      )}
      {htmlToReactParser.parse(currentSpeaker.description)}
      {currentSpeaker.externals?.length && (
        <ul className='external-links'>
          {currentSpeaker.externals.map((link) => {
            return (
              <li>
                <a href={link.link}>{link.title}</a>
              </li>
            );
          })}
        </ul>
      )}
      {currentSpeaker.caption && (
        <div className='speaker-caption'>
          {htmlToReactParser.parse(currentSpeaker.caption)}
        </div>
      )}
    </SpeakerRightContainer>
  );
}
